<template>
  <LayoutModal title="Contact" @modal-close="$emit('modal-close')">
    <div class="md:w-96">
      <div v-if="step === 0 && !loading && !error">
        <h2 class="font-sans font-bold text-sm">{{ $t('ads.questions') }}</h2>
        <ArenaSignupPersonDescription :person="person" />
        <form
          ref="contactForm"
          @submit.prevent="callme()"
          class="grid gap-4 mt-4"
        >
          <FormInput
            v-model="lead.customerName"
            type="text"
            required
            placeholder="Name"
          />
          <FormInput
            v-model="lead.customerEmail"
            type="email"
            required
            placeholder="Email"
          />
          <FormInput
            v-model="lead.customerTelephoneNumber"
            type="tel"
            required
            placeholder="Phone"
          />
        </form>
        <p class="text-sm text-gray-600 my-6 leading-snug text-center">
          {{ $t('youWillBeContactedAt') }}
        </p>
      </div>
      <div v-else-if="loading" class="flex items-center justify-center">
        <CommonLoading />
      </div>
      <div
        v-else-if="error"
        class="flex flex-col items-center justify-center py-8"
      >
        <CommonFontAwesomeIcon
          class="text-5xl text-red mb-6"
          icon="fa-regular fa-xmark-large"
        />
        <h2 class="font-sans text-xl font-medium">{{ $t('errorOccurred') }}</h2>
        <span class="text-sm text-gray-600 leading-5 mt-2">{{
          $t('errorOccurred1')
        }}</span>
      </div>
      <div v-else class="flex flex-col items-center justify-center py-8">
        <CommonFontAwesomeIcon
          class="text-5xl text-grassgreen mb-6"
          icon="fa-light fa-circle-check"
        />
        <h2 class="font-sans text-xl font-medium">
          {{ $t('thankYouForContact') }}
        </h2>
        <p class="text-sm text-gray-600 leading-5 mt-2 text-center">
          {{ $t('youWillBeContactedAt') }}
        </p>
      </div>
    </div>
    <div class="border-t -mx-6 -mb-6 p-4 border-gray-300">
      <CommonButton
        v-if="step === 0"
        bg="bg-blue"
        bgHover="hover:bg-denimblue"
        text="text-white"
        textHover="text-white"
        border="border-none"
        class="w-full"
        size="xlarge"
        @click="callme()"
      >
        {{ $t('ads.callMe') }}
      </CommonButton>
      <CommonButton
        v-else
        text="text-black"
        border="gray-300"
        class="w-full"
        @click="$emit('modal-close')"
      >
        {{ $t('okLowercaseK') }}
      </CommonButton>
    </div>
  </LayoutModal>
</template>

<script setup lang="ts">
import type { HotshotUser, Lead } from '~/typesAuto/apicore/v1'

const route = useRoute()
const dataLayer = useDataLayer()

onMounted(() => {
  dataLayer.commercialSites.contactMeOpen(route.fullPath)
})

const lead = ref({
  customerName: '',
  customerEmail: '',
  customerTelephoneNumber: '',
  utmSource: route.query?.utm_source?.toString(),
  utmMedium: route.query?.utm_medium?.toString(),
  utmCampaign: route.query?.utm_campaign?.toString(),
  utmContent: route.query?.utm_Content?.toString(),
})

const step = ref(0)
const error = ref(false)
const loading = ref(false)
const contactForm = ref<HTMLFormElement>()

async function callme() {
  if (!contactForm.value?.checkValidity()) {
    return
  }
  loading.value = true
  const success = await props.submitLead(lead.value)
  loading.value = false
  if (success) {
    step.value = 1
    dataLayer.commercialSites.contactMeSubmit(route.fullPath, lead)
  } else {
    error.value = true
  }
}
const props = defineProps<{
  person: HotshotUser
  submitLead: (lead: Lead) => Promise<boolean>
}>()
</script>
